<template>
  <div id="register">
    <registers ref="registers" @validateSuccess="submit">
      <div slot="header" style="margin-bottom: 20px">
        <div class="header">
          <span class="orange">供应商注册</span>
        </div>
      </div>
    </registers>
  </div>
</template>
<script>
import _ from "lodash";
import registers from "@/components/login/registers";
import { userRegister } from "@/api/user";

export default {
  components: { registers },
  name: "Register",
  methods: {
    // 提交注册
    submit(formData) {
      // 角色类型(1：供应商，2：采购商)
      let roleType = "1";
      let data = _.pick(formData, [
        "nickName",
        "mobile",
        "password",
        "userType",
        "key",
        "code",
      ]);
      data.roleType = roleType;
      userRegister(data).then((res) => {
        if (res.success) {
          this.$refs.loading = false;
          this.$message.success(res.msg);
          setTimeout(() => {
            this.$router.push({
              name: "UserLogin",
            });
          }, 1000);
        } else {
          this.$refs.registers.loading = false;
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#register {
  background: url("../../../assets/Login/sbig.png") no-repeat center center;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>